<template>
  <div id="app">
    <h1>Intum Video</h1>
    <v-btn
      v-if="!video"
      @click="startVideo"
    >
      Start Video
    </v-btn>
    <v-container ref="meet1" />
  </div>
</template>

<script>
  export default {
    data () {
      return {
        video: false,
      }
    }, // data
    created () {
    }, // created
    methods: {
      startVideo () {
        this.$store.commit('systemStore/setNotification', { visible: true, text: 'Startuję rozmowę video...' })
        this.video = true
        // alert(this.$refs.meet1)
        // const domain = 'video.intum.com'
        // const options = {
        //   roomName: 'radgost123',
        //   width: 1000,
        //   height: 500,
        //   parentNode: this.$refs.meet1, // document.querySelector('#meet'),
        //   interfaceConfigOverwrite: {
        //     // filmStripOnly: true
        //     // DEFAULT_LOCAL_DISPLAY_NAME: 'Intum User',
        //     DEFAULT_REMOTE_DISPLAY_NAME: 'Ala Kot',
        //     DEFAULT_LOCAL_DISPLAY_NAME: 'm1',
        //     // SHOW_JITSI_WATERMARK: 'no',
        //     JITSI_WATERMARK_LINK: 'https://intum.com',
        //   },
        // }
        // const api = new JitsiMeetExternalAPI(domain, options)
      }, // startVideo
    },
  }
</script>
