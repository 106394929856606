// tu beda funkcje pomagające przy routingu
import store from 'src/store/intum-store'
class RouterUtils {
  redirectToLogginIfLoggedOut (to, from, next) {
    const USER_ABSENT = store.state.systemStore.currentUser === null
    if (USER_ABSENT && to.name !== 'login') {
      window.location.href = window.origin
    } else {
      next()
    }
  }

  redirectIfLogged (to, next) {
    const USER_PRESENT = store.state.systemStore.currentUser !== null
    if (USER_PRESENT && to.name !== 'alreadyLoggedIn') {
      next({ name: 'alreadyLoggedIn' })
    } else if (!USER_PRESENT && to.name === 'alreadyLoggedIn') {
      next({ name: 'home' })
    } else {
      next()
    }
  }
}

export default new RouterUtils()
