export default {
  defaultNavigation: {
    menu: [{
      text: '',
      key: '',
      items: [
        { icon: 'mdi-home', key: 'intum.name', href: '/' },
      ]
    }, {
      text: 'Apps',
      key: '',
      items: [
        { icon: 'mdi-video', key: 'organize.name', href: '/organize' },
        { icon: 'mdi-at', key: 'mail.name', href: '/mail' },
        { icon: 'mdi-chip', key: 'connect.name', href: '/connect' },
        { icon: 'mdi-chart-line', key: 'insight.name', href: '/insight' },
        { icon: 'mdi-account', key: 'account.name', href: '/account' },
        // { icon: 'mdi-television-guide', key: "test.name", href: '/x' },

      ]
    }],
    // footer links
    footer: [{
      key: 'common.settings',
    }]
  }
}
