import axios from 'axios'

const storeUrl = window.IntumData.paths.account_members_path

export default {
  namespaced: true,
  state: {
    members: [],
    member: null,
  }, // states

  // gdy nie ma ajaxa
  mutations: {
    reset (state) {
      state.members = []
      state.member = null
    },
    setMembers (state, list) {
      state.members = list
    },
    setMember (state, object) {
      state.member = object
    },
  }, // mutations

  // getters: {
  //   memberOfTeam: (members) => (teamId) => {
  //     return members.filter((m) => m.team_id === teamId)
  //   }
  // }, // getters

  // gdy jest ajax
  actions: {

    index ({ commit }) {
      return new Promise((resolve) => {
        axios.get(`${storeUrl}.json?include=user`, {
        }).then((response) => {
          const data = response.data.sort((a, b) => {
            if (a.get_name < b.get_name) return -1
            if (a.get_name > b.get_name) return 1
            return 0
          })
          commit('setMembers', data)
          resolve(['success', data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // index

    show ({ commit, state }, { userId, forceReload }) {
      return new Promise((resolve) => {
        let found = false

        if (forceReload !== true) {
          state.members.forEach(el => {
            if (el.user_id === userId) {
              found = true
              commit('setMember', el)
              resolve(['success', el])
            }
          })
        }
        if (!found) {
          axios.get(`${storeUrl}/${userId}.json?include=teams,last_answers,last_answers.question,last_answers.question.team&fields[team]=id,name&fields[question]=name,id&fields[team]=name,id`).then((response) => {
            commit('setMember', response.data)
            resolve(['success', response.data])
          }).catch((error) => {
            console.log('error...')
            resolve(['error', error.response.data])
          }).finally(function () {
            console.log('finally')
          })
        }
      })
    }, // show

    new ({ commit }, member) {
      return new Promise((resolve) => {
        commit('setMember', member)
        resolve(['success'])
      })
    }, // new

    create ({ state }, { member }) {
      return new Promise((resolve) => {
        axios.post(`${storeUrl}.json`, { member: member }).then((response) => {
          state.members.unshift(response.data)
          resolve(['success', response.data])
        }).catch((error) => {
          console.log('error in store...', error.response)
          resolve(['error', error.response.data])
        }).finally(function () {
          console.log('finally')
        })
      })
    }, // create

    update ({ state }, { member }) {
      return new Promise((resolve) => {
        const userId = member.get('member[user_id]')
        axios.put(`${storeUrl}/${userId}.json`,
          member,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }).then((response) => {
          // state.list.forEach((el, index) => {
          //   if (el.user_id === object.user_id) {
          //     state.list[index] = response.data
          //   }
          // })
          let i = 0
          for (const el of state.members) {
            if (el.user_id === userId) {
              state.members[i] = response.data
              break
            }
            i++
          }
          state.member = response.data
          resolve(['success', response.data])
        }).catch((error) => {
          resolve(['error', error.response.data])
        })
      })
    }, // update

    destroy ({ commit, state }, { id }) {
      return new Promise((resolve) => {
        axios.delete(`${storeUrl}/${id}.json`).then((response) => {
          commit('setMember', null)
          let i = 0
          for (const el of state.members) {
            if (el.user_id === id) {
              state.members.splice(i, 1)
              break
            }
            i++
          }
          resolve(['success', response.data])
        }).catch((error) => {
          resolve(['error', error.response.data])
        })
      })
    }, // destroy

    find ({ commit, state }, { query }) {
      return new Promise((resolve, reject) => {
        axios.get(`${storeUrl}/find.json`, { params: { query: query } }).then((response) => {
          commit('setMembers', response.data)
          resolve({ status: 'success', data: response.data })
        }).catch(error => {
          console.log(error)
          resolve({ status: 'error', data: error.response.data })
        })
      }) // Promise
    },

    findInTeam ({ commit, state }, { query, teamId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${storeUrl}/find.json`, { params: { query: query, team_id: teamId } }).then((response) => {
          commit('setMembers', response.data)
          resolve({ status: 'success', data: response.data })
        }).catch(error => {
          console.log(error)
          resolve({ status: 'error', data: error.response.data })
        })
      }) // Promise
    },

    workinfoReport ({ commit, state }, { member, month, year, weeklyReports, descriptions }) {
      return new Promise((resolve) => {
        const userId = member.user_id
        axios.get(`${storeUrl}/${userId}/workinfo_reports.json?`,
          { params: { month: month, year: year, weekly_reports: weeklyReports, descriptions: descriptions } }).then((response) => {
          resolve(['success', response.data])
        }).catch((error) => {
          console.log('error...')
          resolve(['error', error.response.data])
        }).finally(function () {
          console.log('finally')
        })
      })
    }, // workinfoReport

    getTasks ({ commit, state }, { userId }) {
      return new Promise((resolve) => {
        axios.get(`${storeUrl}/${userId}.json?include=tasks,tasks.appendixes,tasks.team,tasks.responsible&fields[team]=name,id&fields[responsible]=get_avatar_url,get_name,user_id`).then((response) => {
          // commit('setMember', response.data)
          resolve(['success', response.data])
        }).catch((error) => {
          console.log('error...')
          resolve(['error', error.response.data])
        }).finally(function () {
          console.log('finally')
        })
      })
    }, // getTasks

  }, // actions

} // export default
