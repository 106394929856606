import axios from 'axios'
// import Utils from '../utils/utils'

import i18n from 'src/plugins/i18n'

import Vuetify from '../plugins/vuetify'
import config from 'src/config'

const { product, theme, navigation } = config

const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed } = theme

export default {
  namespaced: true,
  state: {
    currentUser: window.IntumData.currentUser,
    currentMember: window.IntumData.currentMember,
    progress: false,
    systemStoreTest1: 117,
    drawer: false,
    notification: {
      visible: false,
      text: i18n.t('common.data_saved'),
    },
    product,

    // themes and layout configurations
    globalTheme,
    menuTheme,
    toolbarTheme,
    isToolbarDetached,
    isContentBoxed,

    // App.vue main toast
    toast: {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000
    },
    navigation: navigation.defaultNavigation
    // invoiceList: [],
    // currentInvoice: {name: '', email: '', phone: ''},
    // loaders: {payment_list: false},
  }, // states

  getters: {
    // currentUserName: state => {
    currentUserName (state) {
      if (state.currentUser) {
        return state.currentUser.email
      } else {
        return '---'
      }
    }
  },

  mutations: {
    setProduct (state, value) {
      state.product = value
    },
    setNavigation (state, value) {
      state.navigation = value
    },
    setDrawer (state, value) {
      state.drawer = value
    },
    setNotification (state, value) {
      if (value.text) {
        state.notification.text = value.text
        state.notification.color = value.color
      } else {
        state.notification.text = i18n.t('common.created')
      }
      state.notification.visible = value.visible
    },
    setProgress (state, value) {
      state.progress = value
    },

    /**
   * Theme and Layout
   */
    setGlobalTheme: (state, theme) => {
      Vuetify.framework.theme.dark = theme === 'dark'
      state.globalTheme = theme
    },
    setContentBoxed: (state, isBoxed) => {
      state.isContentBoxed = isBoxed
    },
    setMenuTheme: (state, theme) => {
      state.menuTheme = theme
    },
    setToolbarTheme: (state, theme) => {
      state.toolbarTheme = theme
    },
    setToolbarDetached: (state, isDetached) => {
      state.isToolbarDetached = isDetached
    }
  }, // mutations

  actions: {

    myAccounts ({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${window.IntumData.paths.account_accounts_path}.json`, {
        }).then((response) => {
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // myAccounts

    logIn ({ commit, state }, loginForm) {
      return new Promise((resolve, reject) => {
        axios.post(`${window.IntumData.paths.login_path}.json`, {
          log_in: loginForm
        }).then((response) => {
          console.log("ok res:", response.data)
          state.currentUser = response.data
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // logIn

    logOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${window.IntumData.paths.logout_path}.json`, {
        }).then((response) => {
          state.currentUser = null
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // logOut

    signup ({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        axios.post(`${window.IntumData.paths.signup_account_users_path}.json`, {
          user: user
        }).then((response) => {
          state.currentUser = response.data
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // logIn

    resetPassword ({ commit, state }, email) {
      return new Promise((resolve, reject) => {
        axios.post(`${window.IntumData.paths.password_reset_path}.json`, {
          login: email
        }).then((response) => {
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // reset
    changePassword ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`${window.IntumData.paths.password_change_path}.json`, {
          password: params.password,
          password_confirmation: params.passwordConfirmation,
          perishable_token: params.perishable_token
        }).then((response) => {
          resolve(['success', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, // re
    registerAccount ({ commit, state }, prefix) {
      return new Promise((resolve, reject) => {
        axios.post(`${window.IntumData.paths.account_accounts_path}.json`, {
          prefix: prefix,
        }).then((response) => {
          resolve(['created', response.data])
        }).catch(error => {
          console.log(error)
          resolve(['error', error.response.data])
        })
      })
    }, //registerAccount
  }, // actions

} // export default
