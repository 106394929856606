<template>
  <ckeditor
    id="comment_area"
    v-model="object.content"
    :config="editorConfig"
    :label="label"
    :autofocus="focus"
  />
</template>

<script>
  export default {
    props: {
      object: {
        type: Object,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      focus: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data () {
      return {
        editorConfig: {
          extraPlugins: 'colorbutton,font,justify',
          defaultLanguage: "en",
          toolbar: 'Simple',
          toolbar_Simple: [
            ['Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor'],
            ['PasteText', 'PasteFromWord'],
            ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyBlock'],
            ['Font', 'Format', 'Styles'],
            ['Source'],
          ]
        },
      }
    },
  }
</script>
