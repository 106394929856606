/* global___ Vue */
import Vue from 'vue' // testy z globalnym vue
import Vuex from 'vuex'

import SystemStore from 'src/store/system-store'
import MemberStore from 'src/store/member-store'
// import ReportStore from './report-store'
// import AlertStore from './alert-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    systemStore: SystemStore,
    memberStore: MemberStore,
    // reportStore: ReportStore,
    // alertStore: AlertStore,
  }
})
