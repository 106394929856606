
<template>
  <v-dialog
    v-model="destroyDialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        id="button_destroy"
        color="error"
        v-on="on"
      >
        Usuń
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
      >
        Czy na pewno usunąć?
      </v-card-title>

      <v-card-text>
        {{ $slots.default[0].text }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          id="button_destroy_confirm"
          color="error"
          :loading="loading"
          @click="$emit('destroyConfirmed')"
        >
          Usuń
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="destroyDialog = false"
        >
          Nie usuwaj
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      loading: Boolean,
    },
    data () {
      return {
        destroyDialog: false,
      }
    },
  }
</script>
