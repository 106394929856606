/* global___ Vue */
import Vue from 'vue' // testy z globalnym vue
import VueI18n from 'vue-i18n'
import plVuetify from 'vuetify/src/locale/pl.ts'
import enVuetify from 'vuetify/src/locale/en.ts'
import frVuetify from 'vuetify/src/locale/fr.ts'

Vue.use(VueI18n)

// const plLocales = require("locales/pl.yaml")
// const plAccount = require("locales/pl.account.yaml")
// pl.account = plAccount.account

const messages = window.IntumTranslations
const locale = Object.keys(window.IntumTranslations)[0]

if (locale === 'pl') messages[locale].$vuetify = plVuetify
if (locale === 'en') messages[locale].$vuetify = enVuetify
if (locale === 'fr') messages[locale].$vuetify = frVuetify

// plLocales.$vuetify = plVuetify

const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  pl: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  }
}

// const messages = {
//   en: {
//     message: {
//       hello: 'hello world'
//     }
//   },
//   pl: plLocales
// }

export default new VueI18n({
  locale: 'pl', // set locale
  messages, // set locale messages
  dateTimeFormats,
})

// export default new VueI18n({
//   locale: 'pl', // set locale
//   messages, // set locale messages
// })
