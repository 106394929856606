<template>
  <div>
    <h3 class="font-weight-light mt-3">{{ $t('organize/comment.comments') }}</h3>
    <ul class="workinfo_comments">
      <li
        v-for="comment1 in object.comments"
        :key="comment1.id"
        class="chat_bubble"
      >
        <!-- dodajemy klase chat_right żeby komentarze byly prawo-lewo -->
        <div class="chat_text">
          <span v-html="(comment1.content)" />
          <!-- {{ comment1.content }} -->
        </div>

        <div class="chat_data">
          <div
            v-if="comment1.created_by"
          >
            <i-avatar-link
              class="elevation-4 mr-2 ml-2"
              style="border-radius: 100px"
              :member="comment1['created_by']"
              size="30"
            />
            <small>{{ comment1.created_by.get_name }} / {{ comment1.created_at }}</small>

          </div>
        </div>
      </li>
    </ul>
    <i-ckeditor
      id="comment_area"
      :object="comment"
      :label="$t('organize/comment.comment')"
    />
    <v-btn
      id="button_new"
      :loading="saveLoading"
      @click="addComment"
    >
      {{ $t('organize/comment.button.save') }}
    </v-btn>
  </div>
</template>
<style scoped>

ul.workinfo_comments {
  list-style-type: none;
  margin:20px 0;
  padding:0;
}

.chat_bubble.chat_left {
    align-self: flex-start;
    flex: 1 1;
}

.chat_bubble.chat_right {
    align-self: flex-end;
    flex: 1 1;
}

.chat_bubble div.chat_text {
    background-color: #fff;
    color: #363438;
    border-radius: 66px;
    border-bottom-left-radius: 0;
    word-wrap: break-word;
    margin-bottom: 13px;
    font-size: 14px;
    line-height: 20px;
    margin-left: 26px;
    padding: 34px 43px;
    border: 1px solid #dbdbdb;
    margin-top:20px;
}

.chat_bubble.chat_right div.chat_text {
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 30;
    background: #3c77fe;
    color: #fff;
    margin-right: 26px;
    margin-left: 0;
}

.chat_bubble.chat_right .chat_data {
    flex-wrap: wrap-reverse;
}
.chat_bubble.chat_right .chat_data, .chat_bubble.chat_right .chat_data .v-list__tile__avatar {
    flex-direction: row-reverse;
}

.chat_data {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #999;
}

.chat_bubble.chat_right .chat_data .v-avatar {
    margin-left: 10px;
}

.chat_data .v-list__tile__avatar, .chat_data>* {
    justify-content: space-between;
}

</style>
<script>
  export default {
    props: {
      object: {
        type: Object,
        required: true
      },
      // Organize::Answer lub Organize::Task
      objectType: {
        type: String,
        required: true
      },
      focus: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data () {
      return {
        saveLoading: false,
        comment: {},
      }
    },

    methods: {
      addComment () {
        // if (!this.$refs.form.validate()) {
        //   this.focusField()
        //   return
        // }

        this.comment['commentable_id'] = this.object.id
        this.comment['commentable_type'] = this.objectType

        this.saveLoading = true
        this.formErrors = {}

        this.$store.dispatch('commentStore/create', { comment: this.comment }).then(([status, data]) => {
          if (status === 'success') {
            this.comment.content = ''
            this.object['comments'].push(data)
            this.$store.commit('systemStore/setNotification', { visible: true })
          } else {
            console.log('error11', data)
            // alert('e1')
            // this.focusField()
            // this.errorMessage = data
            // console.log("error123:", data)
            // if (Utils.present(data)) {
            //   this.formErrors = data
            //   console.log("formErrors:", this.formErrors)
            //   this.$refs.form.resetValidation()
            // }
          }
          this.saveLoading = false
        })
      }, // addComment
    }
  }
</script>
