<template>
  <a :href="getUrl()">
    <i-avatar
      :member="member"
      :size="size"
    />
  </a>
</template>
<script>
  import IAvatar from 'components/common/IAvatar'

  export default {
    components: {
      IAvatar: IAvatar
    },
    props: {
      member: {
        type: Object,
        required: true,
      },
      history: {
        type: String,
        required: false,
        default: ""
      },
      size: {
        type: String,
        required: false,
        default: "24"
      },
    },
    create () {
      console.log(this.member)
    },

    methods: {
      getName () {
        return this.member.get_name.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-")
      },

      getUrl () {
        if (history !== "") {
          return '/account/members/' + this.member.user_id + '/' + this.getName() + '?history=' + this.history
        } else {
          return '/account/members/' + this.member.user_id + '/' + this.getName()
        }
      }
    }
  }
</script>

