/* global__ Vue Vuetify */
import Vue from 'vue' // testy z globalnym vue
import Vuetify from 'vuetify' // testy z globalnym vue
// import 'vuetify/dist/vuetify.min.css'
// import '@mdi/font/css/materialdesignicons.css' // testy z globalnym vue
// dzięki temu są ikony ale można też ręcznie przez <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
// import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import i18n from 'src/plugins/i18n'
import config from 'src/config'
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    // locales: { pl },
    // current: 'pl',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  },
})
