import icons from './icons'
import theme from './theme'
import navigation from './navigation'
export default {
  // product display information
  product: {
    name: 'Intum',
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  navigation,
}
