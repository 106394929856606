/* global___ Vue */
import Vue from 'vue' // testy z globalnym vue
import vuetify from 'src/plugins/vuetify'
import i18n from 'src/plugins/i18n'
import IBtn from 'components/common/IBtn'
import IBtnDestroy from 'components/common/IBtnDestroy'
import IBreadcrumbs from 'components/common/IBreadcrumbs'
import IFormErrors from 'components/common/IFormErrors'
import IHorizontalToolbar from 'components/common/IHorizontalToolbar'
import IComments from 'components/common/IComments'
import ICKEditor from 'components/common/ICKEditor'
import IAvatar from 'components/common/IAvatar'
import IAvatarLink from 'components/common/IAvatarLink'
// LUX PRO
import App from 'App.vue'

// TAILWIND
import 'packs/tailwind.css'

// CKEditor
import CKEditor from 'ckeditor4-vue'
Vue.use(CKEditor)

// // STYLES
// // Main Theme SCSS
// import 'lib/lux_admin/theme.scss'

// // Animation library - https://animate.style/
// import 'animate.css/animate.min.css'
Vue.component('i-btn', IBtn)
Vue.component('i-btn-destroy', IBtnDestroy)
Vue.component('i-breadcrumbs', IBreadcrumbs)
Vue.component('i-form-errors', IFormErrors)
Vue.component('i-horizontal-toolbar', IHorizontalToolbar)
Vue.component('i-comments', IComments)
Vue.component('i-ckeditor', ICKEditor)
Vue.component('i-avatar', IAvatar)
Vue.component('i-avatar-link', IAvatarLink)

export default function initVue (params) {
  document.addEventListener('DOMContentLoaded', () => {
    if (params.locales) {
      const m1 = i18n.getLocaleMessage('pl')
      Object.assign(m1, params.locales.pl)
      i18n.setLocaleMessage('pl', m1)
    }
    const app = new Vue({
      vuetify,
      router: params.router,
      store: params.store,
      i18n,
      render: h => h(App)
    }).$mount()
    // console.log(app)
    var el = document.getElementById('intum_app')
    el.append(app.$el)
    // document.body.appendChild(app.$el)
  })
}
