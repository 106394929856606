<template>
  <div
    class="d-flex flex-grow-1"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2">
          <div class="text-h6 font-weight-bold text-uppercase primary--text">{{ product.name }}</div>
          <div class="text-overline grey--text">{{ product.version }}</div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="navigation.menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            :href="item.href"
            :target="item.target"
            small
            text
          >
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">

          <!-- search input mobile -->
          <v-text-field
            v-if="showSearch"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            solo
            flat
            autofocus
            @click:append="showSearch = false"
          />

          <div
            v-else
            class="d-flex flex-grow-1 align-center"
          >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <v-spacer class="d-none d-lg-block" />

            <!-- search input desktop -->
            <v-text-field
              ref="search"
              class="mx-1 hidden-xs-only"
              :placeholder="$t('common.search')"
              prepend-inner-icon="mdi-magnify"
              hide-details
              filled
              rounded
              dense
            />

            <v-spacer class="d-block d-sm-none" />

            <v-btn
              class="d-block d-sm-none"
              icon
              @click="showSearch = true"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <toolbar-apps />

            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications />
            </div>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
        :fluid="!isContentBoxed"
      >
        <v-row>
          <slot />
        </v-row>
      </v-container>

      <v-footer
        app
        inset
      >
        <v-spacer />
        <div class="text-overline">
          <a
            :href="intumData.product_app.url"
            target="_blank"
          >{{ intumData.product_app.name }}</a>
          -
          {{ intumData.product_app.version }} |
          Made in Poland <v-icon
            small
            color="pink"
          >
            mdi-heart
          </v-icon>
        </div>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  // navigation menu configurations

  import MainMenu from 'components/intum/MainMenu'
  import ToolbarUser from 'components/intum/ToolbarUser'
  import ToolbarApps from 'components/intum/ToolbarApps'
  import ToolbarNotifications from 'components/intum/ToolbarNotifications'

  export default {
    components: {
      MainMenu,
      ToolbarUser,
      ToolbarApps,
      ToolbarNotifications
    },
    data () {
      return {
        drawer: null,
        showSearch: false,
        intumData: window.IntumData,
      }
    },
    computed: {
      ...mapState('systemStore', ['product', 'isContentBoxed', 'menuTheme', 'toolbarTheme', 'isToolbarDetached', 'navigation'])
    },
    methods: {
      navigateTo (path) {
        if (this.$route.path !== path) this.$router.push(path)
      },
      onKeyup (e) {
        this.$refs.search.focus()
      }
    }
  }
</script>

<style scoped>
.buy-button {
  box-shadow: 1px 1px 18px #ee44aa;
}
</style>
