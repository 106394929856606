<template>
  <v-app style="width: 95%; margin-left: 2.5%; margin-right: 2.5%">
    <!-- <i-app-bar
      title="Intum"
      :disable-nav-icon="true"
    /> -->
    <!-- <insight-drawer /> -->
    <i-app-content />
  </v-app>
</template>

<script>
  // import IAppBar from 'components/common/IAppBar'
  import IAppContent from 'components/common/IAppContent'
  // import InsightDrawer from './InsightDrawer'

  export default {
    name: 'Intum',

    components: {
      // InsightDrawer: InsightDrawer,
      // IAppBar: IAppBar,
      IAppContent: IAppContent,
      // DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      // DashboardCoreView: () => import('./components/core/View'),
    },

  }
</script>
