<template>
  <v-menu
    offset-y
    left
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-view-grid-outline</v-icon>
      </v-btn>
    </template>

    <v-card
      class="d-flex flex-row flex-wrap"
      style="max-width: 280px"
    >
      <!--  -->
      <v-list class="font-weight-light">
        <!--
        <v-list-item href="/">
          <v-list-item-title>{{ $t('intum.name') }}</v-list-item-title>
        </v-list-item>
        -->
        <v-list-item href="/organize">
          <v-list-item-title>{{ $t('organize.name') }}</v-list-item-title>
        </v-list-item>
        <v-list-item href="/mail">
          <v-list-item-title>{{ $t('mail.name') }}</v-list-item-title>
        </v-list-item>
        <v-list-item href="/connect">
          <v-list-item-title>{{ $t('connect.name') }}</v-list-item-title>
        </v-list-item>
        <v-list-item href="/insight">
          <v-list-item-title>{{ $t('insight.name') }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item href="/account">
          <v-list-item-title>{{ $t('account.name') }}</v-list-item-title>
        </v-list-item>

        <!--
        <v-list-item href="/video">
          <v-list-item-title>{{ $t('video.name') }}</v-list-item-title>
        </v-list-item>
        -->

        <!--
        <v-divider />

        <v-list-item @click="$i18n.locale = 'pl'">
          <v-list-item-title>PL</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$i18n.locale = 'en'">
          <v-list-item-title>EN</v-list-item-title>
        </v-list-item>

        <v-list-item href="/api">
          <v-list-item-title>Api UI</v-list-item-title>
        </v-list-item>

        <v-list-item href="/testing">
          <v-list-item-title>Testowanie</v-list-item-title>
        </v-list-item>
        -->
      </v-list>
    <!--  -->
      <!-- <div
        v-for="app in apps"
        :key="app.link"
        class="app-tile pa-3 text-center"
        style="flex: 0 50%"
        @click="navigateTo(app.link)"
      >
        <v-icon color="primary">{{ app.icon }}</v-icon>
        <div class="font-weight-bold mt-1">{{ app.key ? $t(app.key) : app.text }}</div>
        <div class="caption">{{ app.subtitleKey ? $t(app.subtitleKey) : app.subtitle }}</div>
      </div> -->
    </v-card>
  </v-menu>
</template>

<script>
  // import config from '../../configs'
  /*
|---------------------------------------------------------------------
| Toolbar Apps Component
|---------------------------------------------------------------------
|
| Quickmenu for applications in the toolbar
|
*/
  export default {
    data () {
      return {
        // apps: config.toolbar.apps
      }
    },
    methods: {
      navigateTo (path) {
        if (this.$route.path !== path) this.$router.push(path)
      }
    }
  }
</script>

<style lang="scss" scoped>
.app-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--v-background-base);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
