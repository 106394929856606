/* global___ Vue */
import Vue from 'vue' // testy z globalnym vue
import VueRouter from 'vue-router'
import store from 'src/store/intum-store'
import RouterUtils from 'src/router-utils.js'

import App from 'components/intum/App.vue'
import Dashboard from 'components/intum/Dashboard.vue'
import Video from 'components/video/Video.vue'

const Login = () => import(/* webpackChunkName: "auth-signin" */ 'components/common/Login.vue')
const Register = () => import(/* webpackChunkName: "i-common" */ 'components/common/Register.vue')
const MyAccounts = () => import(/* webpackChunkName: "i-common" */ 'components/common/MyAccounts.vue')
const PasswordReset = () => import(/* webpackChunkName: "i-common" */ 'components/common/PasswordReset.vue')
const PasswordChange = () => import(/* webpackChunkName: "i-common" */ 'components/common/PasswordChange.vue')
const AlreadyLoggedIn = () => import(/* webpackChunkName: "i-common" */ 'components/pages/utility/LoggedInPage.vue')
const LandingHome = () => import(/* webpackChunkName: "landingHome" */ 'components/pages/landing/HomePage.vue')
const LandingPricing = () => import(/* webpackChunkName: "landingPricing" */ 'components/pages/landing/PricingPage.vue')
const RegisterAccount = () => import(/* webpackCunkName: "i-common" */ 'components/common/RegisterAccount.vue')
Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  // base: '/',
  routes: [
    { path: '/login', component: Login, beforeEnter: (to, from, next) => RouterUtils.redirectIfLogged(to, next), name: 'login', meta: { layout: 'auth' } },
    { path: '/signup', component: Register, beforeEnter: (to, from, next) => RouterUtils.redirectIfLogged(to, next), name: 'register', meta: { layout: 'auth' } },
    { path: '/signup/account', component: RegisterAccount, name: 'registerAccount', meta: { layout: 'auth' } },
    { path: '/password/reset', component: PasswordReset, beforeEnter: (to, from, next) => RouterUtils.redirectIfLogged(to, next), name: 'passwordReset', meta: { layout: 'auth' } },
    { path: '/password/change', component: PasswordChange, beforeEnter: (to, from, next) => RouterUtils.redirectIfLogged(to, next), name: 'passwordChange', meta: { layout: 'auth' } },
    { path: '/register/account', component: MyAccounts, name: 'accountNew' },
    { path: '/my_accounts', component: MyAccounts, name: 'myAccounts' },
    { path: '/already_logged_in', component: AlreadyLoggedIn, name: 'alreadyLoggedIn', beforeEnter: (to, from, next) => RouterUtils.redirectIfLogged(to, next), meta: { layout: 'auth' } },
    { path: '/landing', name: 'landingHome', component: LandingHome, meta: { layout: 'landing' } },
    { path: '/landing/pricing', name: 'landingPricing', component: LandingPricing, meta: { layout: 'landing' } },
    {
      path: '/',
      component: App,
      beforeEnter: (to, from, next) => {
        if (store.state.systemStore.currentUser === null) {
          next({ name: 'login' })
        } else {
          next()
        }
      },
      children: [
        { path: '/', component: Dashboard, name: 'home' },
        { path: '/video', component: Video, name: 'video' },
      ]
    },
    {
      path: '*',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ 'components/pages/error/NotFoundPage.vue'),
      meta: {
        layout: 'error'
      }
    },
  ],
})
