<template>
  <v-row
    class="mb-1"
  >
    <v-col>
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
    </v-col>
    <v-col
      v-if="menu"
    >
      <v-row
        justify="end"
        class="pa-3"
      >
        <v-menu
          offset-y
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn
              id="button_horizontal_toolbar"
              :elevation="1"
              dark
              fab
              bottom
              right
              color="grey"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="font-weight-light">
            <slot name="menu" />
          </v-list>
        </v-menu>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    // props: {
    //   name: {
    //     type: String,
    //     required: false
    //   },
    // },
    props: {
      menu: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>
