<template>
  <v-avatar
    :color="initialColors[1]"
    :size="size"
  >
    <img
      v-if="member.get_avatar_url"
      :alt="member.get_name"
      :src="getAvatar"
    >
    <v-avatar
      v-else
      :color="initialColors[0]"
      :size="innerSize"
    >
      <span
        class="white--text"
      >
        {{ memberInitials[0] }}
      </span>
      <v-overlay
        v-if="member.on_holiday"
        :value="true"
        :opacity="0.3"
        :absolute="true"
      >
        <v-avatar
          :size="size"
        >
          <v-icon
            color="white"
            dark
            :size="size/3*2"
          >
            mdi-clock-outline
          </v-icon>
        </v-avatar>
      </v-overlay>
    </v-avatar>
  </v-avatar>
</template>
<script>
  export default {
    props: {
      member: {
        type: Object,
        required: true,
      },
      size: {
        type: String,
        required: false,
        default: "24"
      },
    },
    computed: {
      innerSize () {
        const outerSize = parseInt(this.size)
        return Math.max(outerSize * 4 / 5, outerSize - 10)
      },
      memberInitials () {
        var name = this.member.get_name
        if (name === null) {
          name = "A B"
        }
        const nameWords = name.split(/[\s_-]+/)
        if (nameWords.length >= 2) {
          return nameWords[0][0] + nameWords[1][0]
        } else {
          return name.slice(0, 2)
        }
      },
      initialColors () {
        return this.memberInitials.split('').map(initial => this.colors[initial.toLowerCase().charCodeAt(0) % this.colors.length])
      },
      colors () {
        return [
          'red',
          'pink',
          'purple',
          'deep-blue',
          'indigo',
          'blue',
          'light-blue',
          'cyan',
          'teal',
          'green',
          'light-green',
          'lime',
          'yellow',
          'amber',
          'orange',
          'deep-orange',
          'brown',
          'blue-gray',
        ]
      },

      getAvatar () {
        if (this.size <= 40) return this.member.get_avatar_url
        if (this.size <= 100) return this.member.get_avatar_url_medium
        return this.member.get_avatar_url_large
      }
    }
  }
</script>

