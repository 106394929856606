<template>
  <div id="app">
    <!--     <i-breadcrumbs :items="breadcrumbs" /> -->

    <v-container>
      <p>{{ $t("intum.name") }}</p>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            id="card_organize"
            outlined
            link
            href="/organize"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <!-- <div class="overline mb-4">OVERLINE</div> -->
                <v-list-item-title class="text-h5 mb-1">
                  {{
                    $t("organize.name")
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t("organize.desc")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                class="mt-2 mb-2"
                size="80"
                tile
              >
                <img :src="require('assets/dashboard/organize.png')">
              </v-avatar>
            </v-list-item>

            <!-- <v-card-actions>
                <v-btn text>Button</v-btn>
                <v-btn text>Button</v-btn>
              </v-card-actions> -->
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-card
            id="card_mail"
            outlined
            link
            href="/mail"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <!-- <div class="overline mb-4">OVERLINE</div> -->
                <v-list-item-title class="text-h5 mb-1">
                  {{
                    $t("mail.name")
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t("mail.desc")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                class="mt-2 mb-2 ml-2"
                size="80"
                tile
              >
                <img :src="require('assets/dashboard/mail.png')">
              </v-avatar>
            </v-list-item>

            <!-- <v-card-actions>
                <v-btn text>Button</v-btn>
                <v-btn text>Button</v-btn>
              </v-card-actions> -->
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-card
            id="card_connect"
            outlined
            link
            href="/connect"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <!-- <div class="overline mb-4">OVERLINE</div> -->
                <v-list-item-title class="text-h5 mb-1">
                  {{
                    $t("connect.name")
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t("connect.desc")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                class="mt-2 mb-2 ml-2"
                size="80"
                tile
              >
                <img :src="require('assets/dashboard/inconector.png')">
              </v-avatar>
            </v-list-item>

            <!-- <v-card-actions>
                <v-btn text>Button</v-btn>
                <v-btn text>Button</v-btn>
              </v-card-actions> -->
          </v-card>
        </v-col>

        <!-- <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            link
            href="/class"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{ $t('class.name') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('class.desc') }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </v-col> -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            id="card_insight"
            outlined
            link
            href="/insight"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  {{
                    $t("insight.name")
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t("insight.desc")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                class="mt-2 mb-2 ml-2"
                size="80"
                tile
              >
                <img :src="require('assets/dashboard/insight.png')">
              </v-avatar>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-card
            id="card_account"
            outlined
            link
            href="/account"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <!-- <div class="overline mb-4">OVERLINE</div> -->
                <v-list-item-title class="text-h5 mb-1">
                  {{
                    $t("account.name")
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t("account.desc")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                class="mt-2 mb-2 ml-2"
                size="80"
                tile
              >
                <img :src="require('assets/dashboard/profile.png')">
              </v-avatar>
            </v-list-item>

            <!-- <v-card-actions>
                <v-btn text>Button</v-btn>
                <v-btn text>Button</v-btn>
              </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        breadcrumbs: [
          {
            text: "Insight",
            to: { name: "home" },
            exact: true,
          },
        ],
      }
    }, // data
  }
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
