<template>
  <v-app>
    <!-- Layout component -->
    <component
      :is="currentLayout"
      v-if="isRouterLoaded"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
    <!-- Demo customization menu -->
    <!-- <customization-menu /> -->
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'

  import config from 'src/config'
  // Demo Menu
  // import CustomizationMenu from 'components/demo/CustomizationMenu'
  // Layouts
  import defaultLayout from 'components/layout/DefaultLayout'
  import landingLayout from 'components/layout/LandingLayout'
  import authLayout from 'components/layout/AuthLayout'
  import errorLayout from 'components/layout/ErrorLayout'

  /*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
  export default {
    components: {
      defaultLayout,
      landingLayout,
      authLayout,
      errorLayout,
      // CustomizationMenu
    },
    computed: {
      ...mapState('systemStore', ['toast']),
      isRouterLoaded: function () {
        if (this.$route.name !== null) return true

        return false
      },
      currentLayout: function () {
        const layout = this.$route.meta.layout || 'default'

        return layout + 'Layout'
      }
    },
    head: {
      link: [
        // adds config/icons into the html head tag
        ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
      ]
    }
  }
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
