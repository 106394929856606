<template>
  <v-menu
    offset-y
    left
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        id="user-avatar"
        icon
        class="elevation-2"
        v-on="on"
      >
        <!--
        <v-badge
          color="success"
          dot
          bordered
          offset-x="10"
          offset-y="10"
        >
        -->
        <i-avatar
          v-if="currentMember"
          :member="currentMember"
          size="40"
        />
        <!--
        </v-badge>
        -->
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list
      dense
      nav
    >
      <v-list-item
        :href="currentMember ? `/account/members/${currentMember.user_id}` : `/account/profile`"
      >
        <v-list-item-icon>
          <v-icon small>mdi-account-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ currentUserName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        id="button-logout"
        @click="logOut"
      >
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('account/user.button.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item href="/api">
        <v-list-item-title>Api UI</v-list-item-title>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item
        v-if="$i18n.locale != 'pl'"
        @click="$i18n.locale = 'pl'"
      >
        <v-list-item-title>PL</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$i18n.locale != 'en'"
        @click="$i18n.locale = 'en'"
      >
        <v-list-item-title>EN</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
  // import config from '../../configs'
  /*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
  import { mapGetters, mapState } from 'vuex'

  export default {
    data () {
      return {
      }
    },
    computed: {
      ...mapState('systemStore', ['currentUser']),
      ...mapState('systemStore', ['currentMember']),
      ...mapGetters('systemStore', ['currentUserName']),
    },
    created () {
    },
    methods: {
      logOut () {
        this.$store.dispatch('systemStore/logOut').then(([status, data]) => {
          if (status === 'success') {
            window.location.href = window.origin
          } else {
            console.log(data)
          }
        })
      }, // logOut
    }
  }
</script>
