<template>
  <v-breadcrumbs
    :items="items"
    large
  >
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        // variant: window.IntumData.variant,
      }
    },
  }
</script>

