<template>
  <v-alert
    v-if="errorMessage"
    type="error"
  >
    {{ $t('common.error_message') }}
    <a
      @click="showErrorDetails = !showErrorDetails"
    >[...]</a>
    <div v-if="showErrorDetails">{{ errorMessage }}</div>
  </v-alert>
</template>
<script>
  export default {
    props: {
      errorMessage: {
        type: [String, Object],
        required: false,
        default: null,
      },
    },
    data () {
      return {
        showErrorDetails: false,
      }
    }
  }
</script>
