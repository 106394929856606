<template>
  <v-btn
    v-hotkey="keymap"
    @click="click1"
  >
    {{ label1 }}
  </v-btn>
</template>
<script>
  export default {
    props: {
      kind: { type: String, default: 'add' },
    },

    computed: {

      keymap () {
        const out = {}
        if (this.kind === 'add') out['ctrl+n'] = this.click1
        // console.log('out', out)
        return out
      }, // keymap

      label1 () {
        return `${this.$slots.default[0].text} [${this.getHotkey()}]`
      },

    }, // computed

    methods: {

      getHotkey () {
        let hotkey = null
        if (this.kind === 'add') {
          hotkey = 'n'
        }
        return hotkey
      },

      click1 () {
        // alert(1)
        this.$emit('click')
        // alert(1)
        // this.$router.push({ name: 'memberNew'})
      },

    }, // methods

  }
</script>
