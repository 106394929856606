<template>
  <v-main
    class="mb-6"
    style="padding: 0"
  >
    <v-progress-linear
      v-if="progress"
      style="position: fixed;"
      indeterminate
      color="primary"
    />

    <router-view />

    <v-snackbar
      v-model="notification.visible"
      :bottom="true"
      :right="true"
      :multi-line="false"
      :timeout="4000"
      :color="notification.color"
    >
      <!-- <router-link :to="{name: 'reports', params: {id: 12}}">
        {{snackbar.text}}
      </router-link> -->
      {{ notification.text }}
      <v-btn
        dark
        icon
        @click="setNotification({visible: false})"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!--
    <v-footer
      color="intumColor"
      dark
      padless
    >
      <v-spacer/>
      <span class="font-weight-light overline caption__" >
        Intum Insight v0.2 &nbsp;
      </span>
    </v-footer>
    -->
  </v-main>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'IntumAppContenr',

    computed: {
      ...mapState('systemStore', ['notification', 'progress']),
    }, // computed

    methods: {
      ...mapMutations('systemStore', ['setNotification', 'setProgress']),
    }, // methods

  }
</script>
